import React, { useEffect } from 'react';
import AppLayout from '../components/layout/AppLayout';
import { Router } from '@gatsbyjs/reach-router';
import { useDispatch } from 'react-redux';
import { enableAuth } from '../store/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { navigate } from '@gatsbyjs/reach-router';

const Workspace = () => {
  const dispatch = useDispatch();
  const { isLoading, isAuthenticated, user } = useAuth0();

  useEffect(() => {
    dispatch(enableAuth());
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const redirectToDashboard = async () => {
      navigate('/');
    };

    if (isAuthenticated) {
      redirectToDashboard();
    }
  }, [isAuthenticated, isLoading, user]);

  return (
    <AppLayout>
      {/*@ts-ignore*/}
      <Router default="/"></Router>
    </AppLayout>
  );
};
export default Workspace;
